import React from 'react';
import { motion } from 'framer-motion';
import { container, title, content } from '../../../commons/variants';
import styled from '@emotion/styled';
import theme from '../../../commons/theme';
import mainImage from '../../../assets/image/main_bg.png';
import { t } from 'i18next';
import useCheckEng from '../../../util/useCheckEng';

function Main() {
    const isEng = useCheckEng();
    return (
        <MainWrapper id="main">
            <motion.div
                variants={container}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true }}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
                <motion.h3 variants={title} transition={{ duration: 0.7 }}>
                    {t('main.mtitle')}
                </motion.h3>
                <motion.div variants={title} transition={{ duration: 0.7 }}>
                    <h1 className={isEng ? 'line-height-mobile' : ''}>{t('main.mtitle2')}</h1>
                    <h1 className={isEng ? 'line-height-mobile' : ''}>{t('main.mtitle3')} </h1>
                    <h1 className={isEng ? 'line-height-mobile' : ''}>{t('main.mtitle4')}</h1>
                </motion.div>
                <motion.pre variants={content} transition={{ duration: 0.7 }}>
                    {t('main.mtext')}
                </motion.pre>
            </motion.div>
        </MainWrapper>
    );
}

export default Main;

const MainWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 700px;
    margin-top: 60px;
    background-image: url(${mainImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
    color: ${theme.grey_900};

    h3 {
        margin-bottom: 48px;
        text-align: center;
        color: ${theme.primaryGreen};
        font-family: 'Futura PT';
        font-size: 2.6rem;
        font-weight: 600;
    }

    h1 {
        text-align: center;
        font-family: ${theme.mainTitle};
        font-size: 4.2rem;
    }

    .line-height-mobile {
        line-height: 1.2;
    }
    pre {
        margin-top: 32px;
        text-align: center;
        font-family: ${theme.desc};
        font-size: 1.6rem;
    }
`;

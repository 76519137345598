import styled from '@emotion/styled';
import theme from '../../../commons/theme';

export const FooterWrapper = styled.footer`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: ${theme.grey_900};
`;

export const FooterMain = styled.section`
    display: flex;
    justify-content: space-between;
    width: 1090px;
    padding: 71px 0px 100px 0px;
    color: white;
`;

export const LogoImage = styled.img`
    width: 247px;
    height: 37px;
    object-fit: contain;
`;

export const CompanyInfo = styled.section`
    font-family: ${theme.desc};
`;

export const CompanyInfoTitle = styled.h1`
    display: inline-block;
    margin-bottom: 16px;
    margin-right: 26px;
    font-family: ${({ bold }) => (bold ? theme.title : theme.desc)};
    font-size: 1.6rem;

    :hover {
        cursor: ${({ bold }) => bold && 'pointer'};
    }
`;

export const CompanyInfoText = styled.span`
    display: block;
    margin-top: ${({ mb }) => mb};
    font-size: 1.4rem;
`;

export const CompanyAdress = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: ${theme.desc};
    h1 {
        margin-bottom: 6px;
        font-size: 1.6rem;
    }

    span {
        display: block;
        font-size: 1.4rem;
    }
`;
export const CompanyLink = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    button {
        display: flex;
        justify-content: space-between;
        padding: 0px 25px;
        width: 224px;
        height: 48px;
        border: 1px solid white;
        border-radius: 100px;
        color: white;
        font-family: ${theme.subTitle};
        font-size: 1.6rem;

        :hover {
            border: 1px solid white;
            background-color: transparent;
        }
    }
`;

export const Meun = styled.ul`
    list-style: none;
    position: absolute;
    top: -225px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border: 1px solid white;
    border-radius: 15px;
    background-color: ${theme.grey_900};
    font-family: ${theme.subTitle};
    font-size: 1.6rem;

    li {
        margin: 10px;

        :hover {
            cursor: pointer;
        }
    }
`;

import React from 'react';
import styled from '@emotion/styled';
import theme from '../../../commons/theme';
import { memberShipMenu } from '../../../commons/data';
import Button from '@mui/material/Button';
import { Link } from 'react-scroll';
import Title from '../Title';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import useCheckEng from '../../../util/useCheckEng';

const MemberShipWrapper = styled.div`
    width: 1090px;
    margin-top: 88px;
    /* border: 1px solid red; */
`;

const MemberShipContent = styled.div`
    display: flex;
    width: 100%;
    height: 280px;
    margin-top: 20px;
    padding-top: 33px;
    padding-left: 33px;
    border-radius: 4px;
    border: 2px solid white;
    box-shadow: 2px 4px 15px rgba(66, 66, 66, 0.1);

    :hover {
        border: 2px solid ${theme.primaryGreen};
    }
`;

const MemberShipContentTitle = styled.div`
    width: 30%;
    height: 100%;

    h1 {
        color: ${theme.primaryGreen};
        font-family: ${theme.title};
        font-size: 3.4rem;
    }

    span {
        color: ${theme.grey_800};
        font-family: ${theme.subTitle};
        font-size: 2.2rem;
    }
`;

const MemberShipContentInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 70%;
    height: 100%;
    padding: 30px 0px 40px 0px;
    padding-right: 80px;
`;

const InfoMenu = styled.div`
    display: flex;
    width: 30%;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h5 {
        margin-left: 14px;
        color: ${theme.grey_800};
        font-family: ${theme.subTitle};
        font-size: 1.8rem;
        font-weight: 500;
        white-space: nowrap;
    }

    span {
        margin-left: 14px;
        color: ${theme.grey_700};
        font-family: ${theme.text};
        font-size: 1.4rem;
    }

    img {
        width: 50px;
        height: 50px;
    }
`;

function MemberShip({ setService }) {
    const { i18n } = useTranslation();
    const isEng = useCheckEng();
    return (
        <MemberShipWrapper style={{ marginTop: isEng ? '0px' : '88px' }}>
            {i18n.language === 'ko' && (
                <>
                    <Title title={`멤버십 플랜`} text={`1000 원 부터 / 두 당`} />
                    <MemberShipContent>
                        <MemberShipContentTitle>
                            <h1>통합 서비스</h1>
                            <span>매니저 케어 라이브 에코</span>
                            <Link to="apply" smooth={true} duration={1500}>
                                <Button onClick={() => setService('통합')} variant="contained" sx={{ mt: '60px' }}>
                                    문의하기
                                </Button>
                            </Link>
                        </MemberShipContentTitle>
                        <MemberShipContentInfo>
                            {memberShipMenu.map(el => (
                                <InfoMenu key={el.id}>
                                    <img src={el.icon} />
                                    <div>
                                        {el.title.map(text => (
                                            <h5 key={text}>{text}</h5>
                                        ))}
                                        <span>{el.sub}</span>
                                    </div>
                                </InfoMenu>
                            ))}
                        </MemberShipContentInfo>
                    </MemberShipContent>
                </>
            )}
        </MemberShipWrapper>
    );
}

export default MemberShip;

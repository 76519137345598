import styled from '@emotion/styled';
import mainImage from '../../../assets/image/main_bg.png';
import theme from '../../../commons/theme';
import i18n from 'i18next';

export const MainWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 924px;
    margin-top: 100px;
    padding-bottom: 192px;
    background-image: url(${mainImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    color: ${theme.grey_900};

    h3 {
        margin-bottom: 48px;
        text-align: center;
        color: ${theme.primaryGreen};
        font-family: 'Futura PT';
        font-size: 3.6rem;
        font-weight: 600;
    }

    h1 {
        text-align: center;
        font-family: ${theme.mainTitle};
        font-size: 8.5rem;
        line-height: ${props => (props.isEng ? 1.1 : 'unset')};
    }
    pre {
        margin-top: 32px;
        margin-bottom: 90px;
        text-align: center;
        font-family: ${theme.text};
        font-size: 2.2rem;
    }

    button {
        width: 298px;
        height: 72px;
        margin-top: 32px;
        margin-bottom: 12px;
        border: none;
        border-radius: 100px;
        box-shadow: none;
        background-color: ${theme.primaryGreen};
        color: white;
        font-family: ${theme.subTitle};
        font-size: 2.2rem;

        :hover {
            box-shadow: none;
            background-color: ${theme.hoverGreen};
            color: white;
        }
    }
`;

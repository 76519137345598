import styled from '@emotion/styled';
import React, { useState } from 'react';
import theme from '../../../commons/theme';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LINKS from '../../../commons/links';
import AidkrLogo from '../../../assets/image/aidkr_log.png';
import AidkrLogoEng from '../../../assets/image/aidkr_log_eng.png';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { i18n } = useTranslation();
    const [isView, setIsView] = useState(false);
    const handleMenu = event => {
        setIsView(false);
        event.target.id && window.open(`${LINKS[event.target.id]}`);
    };
    return (
        <FooterWrapper>
            <FooterContent>
                <div style={{ position: 'relative' }}>
                    <Button onClick={() => setIsView(pre => !pre)} endIcon={<ArrowDropDownIcon />}>
                        {t('footer.button')}
                    </Button>
                    {isView && (
                        <Meun onClick={handleMenu}>
                            <li id="aidkrHomepage">{t('footer.button2')}</li>
                            <li id="aidkrBlog">{t('footer.button3')}</li>
                            <li id="farmsplanApp">{t('footer.button4')}</li>
                            <li id="farmsplanMarket">{t('footer.button5')}</li>
                            <li id="gullyjumper">{t('footer.button6')}</li>
                        </Meun>
                    )}
                </div>
                <Title mb="12px">{t('footer.companyTitle')}</Title>
                <br />
                <PrivacyPolicy onClick={() => window.open('https://www.aidkr.com/privacy-policy')}>
                    {t('footer.companyTitle2')}
                </PrivacyPolicy>
                <PrivacyPolicy onClick={() => window.open('https://www.aidkr.com/terms')}>
                    {t('footer.companyTitle3')}
                </PrivacyPolicy>
                <br />
                <br />
                <Text mb="8px">{t('footer.companyText')}</Text> <br />
                <Text>{t('footer.companyText2')}</Text> <br />
                <Title mt="22px" mb="4px">
                    {t('footer.adress')}
                </Title>
                <br />
                <Text>{t('footer.adress_text')}</Text> <br />
                <Title mt="15px">{t('footer.adress2')}</Title> <br />
                <Text>{t('footer.adress_text2')}</Text> <br />
                {i18n.language === 'ko' ? <Logo src={AidkrLogo} alt="logo" /> : <Logo src={AidkrLogoEng} alt="logo" />}
                <Text>{t('footer.companyText3')}</Text>
            </FooterContent>
        </FooterWrapper>
    );
}

export default Footer;

const FooterWrapper = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 52px;
    padding-bottom: 66px;
    background-color: ${theme.grey_900};
`;

const FooterContent = styled.div`
    width: 295px;

    button {
        display: flex;
        justify-content: space-between;
        padding: 0px 25px;
        width: 295px;
        height: 48px;
        margin-bottom: 36px;
        border: 1px solid white;
        border-radius: 100px;
        color: white;
        font-family: ${theme.subTitle};
        font-size: 1.6rem;

        :hover {
            border: 1px solid white;
            background-color: transparent;
        }
    }
`;

const Meun = styled.ul`
    list-style: none;
    position: absolute;
    bottom: -225px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border: 1px solid white;
    border-radius: 15px;
    background-color: ${theme.grey_900};
    color: white;
    font-family: ${theme.subTitle};
    font-size: 1.6rem;

    li {
        margin: 10px;

        :hover {
            cursor: pointer;
        }
    }
`;

const Title = styled.span`
    display: inline-block;
    margin-right: 22px;
    margin-top: ${({ mt }) => mt};
    margin-bottom: ${({ mb }) => mb};
    color: white;
    font-family: ${theme.desc};
    font-size: 1.4rem;
    white-space: nowrap;
`;

const PrivacyPolicy = styled.span`
    display: inline-block;
    margin-right: 22px;
    margin-top: ${({ mt }) => mt};
    margin-bottom: ${({ mb }) => mb};
    color: white;
    font-family: ${theme.title};
    font-size: 1.4rem;
    white-space: nowrap;

    :hover {
        cursor: pointer;
    }
`;

const Text = styled.span`
    display: inline-block;
    margin-top: ${({ mt }) => mt};
    margin-bottom: ${({ mb }) => mb};
    color: white;
    font-family: ${theme.desc};
    font-size: 1.2rem;
    white-space: nowrap;
`;

const Logo = styled.img`
    margin: 40px 0px;
    width: 190px;
    height: 27px;
    object-fit: cover;
`;
